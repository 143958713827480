import React from 'react';
import { graphql } from 'gatsby';
import WebinarsPage from 'components/pages/WebinarsPage';

const seo = {
  title:
    "Soyez incollable sur l'analyse sémantique et émotionnelle avec nos livres blancs",
  keywords: [],
  description:
    'Découvrez nos livres blancs sectoriels pour en savoir plus sur l’analyse sémantique et émotionnelle afin d’améliorer votre NPS et réduire votre churn.',
};

export default props => <WebinarsPage {...props} seo={seo} whitepaper />;

export const pageQuery = graphql`
  query {
    dataIntroBlockTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "whitepaper" }
        blockType: { eq: "introBlock" }
        component: { eq: "title" }
        lang: { eq: "fr" }
      }
    ) {
      html
    }
    allImages: allFile(filter: { relativeDirectory: { eq: "bubbleImages" } }) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 550, quality: 100) {
            presentationWidth
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    allWebinars: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "whitepaper" }
          blockType: { eq: "webinarBlock" }
          lang: { eq: "fr" }
        }
      }
      sort: { fields: [frontmatter___index] }
    ) {
      nodes {
        frontmatter {
          title
          id
          subtitle
          program
        }
        html
      }
    }
    dataBlockLinkBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "clientCase" }
          blockType: { eq: "linkBlock" }
          lang: { eq: "fr" }
        }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          subTitle
          color
          link
          buttonText
        }
      }
    }
  }
`;
